<template>
  <div class="study-list">
    <!-- <el-card>
      <el-form v-model="form" label-width="100">
        <el-form-item label="您已选择">
          <el-tag
              :key="tag"
              v-for="tag in tags"
              closable
              :disable-transitions="false"
              @close="handleClose(tag)">
            {{tag}}
          </el-tag>
        </el-form-item>

        <el-form-item label="更多选择">
          <el-select :value="form.name" size="mini" placeholder="教材书（资料）名称">
            <el-option label="" value=""></el-option>
          </el-select>
          <el-select :value="form.name" size="mini" placeholder="第几版" style="width: 120px;">
            <el-option label="" value=""></el-option>
          </el-select>
          <el-select :value="form.name" size="mini" placeholder="封面标志性说明，适用人员，适用范围" style="width: 260px;">
            <el-option label="" value=""></el-option>
          </el-select>
          <el-select :value="form.name" size="mini" placeholder="题目、章节名称" style="width: 140px;">
            <el-option label="" value=""></el-option>
          </el-select>
          <el-select :value="form.name" size="mini" placeholder="主编" style="width: 100px;">
            <el-option label="" value=""></el-option>
          </el-select>
          <el-select :value="form.name" size="mini" placeholder="副主编" style="width: 100px;">
            <el-option label="" value=""></el-option>
          </el-select>
          <el-select :value="form.name" size="mini" placeholder="出版发行(社)" style="width: 140px;">
            <el-option label="" value=""></el-option>
          </el-select>
          <el-select :value="form.name" size="mini" placeholder="出版日期" style="width: 120px;">
            <el-option label="" value=""></el-option>
          </el-select>
          <el-select :value="form.name" size="mini" placeholder="图片制作上传者姓名" style="width: 200px;">
            <el-option label="" value=""></el-option>
          </el-select>
          <el-select :value="form.name" size="mini" placeholder="图片制作上传时间" style="width: 200px;">
            <el-option label="" value=""></el-option>
          </el-select>
          <el-select :value="form.name" size="mini" placeholder="图片制作上传者所属单位（首选）或曾履职单位，学习、毕业学校" style="width: 400px;">
            <el-option label="" value=""></el-option>
          </el-select>
          <el-select :value="form.name" size="mini" placeholder="适用于哪年哪月测试观看" style="width: 200px;">
            <el-option label="" value=""></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </el-card> -->

    <el-card class="margin-top padding-bottom">
      <el-table style="width: 100%;" :data="rows">
        <el-table-column
            type="index"
            label="序号"
            width="50">
        </el-table-column>
        <el-table-column
            prop="img"
            label="封面"
            width="60">
          <template slot-scope="scope">
            <el-image :src="scope.row.img" style="width: 100%;" @click="openPage(scope.row)"></el-image>
          </template>
        </el-table-column>
        <el-table-column
            prop="title"
            label="教材名称"
            width="120">
        </el-table-column>
        <el-table-column
            prop="desc"
            label="">
        </el-table-column>
      </el-table>

      <!-- <el-pagination
          background
          layout="prev, pager, next"
          :total="1000">
      </el-pagination> -->
	  
	  
	  
    </el-card>
	
	
	
  </div>
</template>

<script>
  export default {
    name: "StudyList",
    data() {
      return {
        tags: [
          '标签一', '标签二', '标签三'
        ],
        form: {},
        rows: [
          {
            img: require('../../assets/avatar/上官芝.jpg'),
            title: "11一本好书",
            type: "video",
            desc: '版次；封面标志性说明；适用人员；适用范围；题目，章节；主编；副主编；出版发行（社）；出版日期；图片制作上传者姓名； 图片制作上传者单位（首选）或曾履职单位/学习/毕业学校（没有可不填）；图片制作上传时间（按最新由上往下排列）； 适用于哪年哪月至哪年哪月测试观看（不填时，自动选择为最新的）；（录制拍照剪辑制片等电子软件工程技术人员，含本公司人员 及为制造者服务的人员，后者由制作者根据个人意愿填写）'
          },
          {
            img: require('../../assets/avatar/上官芝.jpg'),
            title: "一本好书",
            type: "book",
            desc: '版次；封面标志性说明；适用人员；适用范围；题目，章节；主编；副主编；出版发行（社）；出版日期；图片制作上传者姓名； 图片制作上传者单位（首选）或曾履职单位/学习/毕业学校（没有可不填）；图片制作上传时间（按最新由上往下排列）； 适用于哪年哪月至哪年哪月测试观看（不填时，自动选择为最新的）；（录制拍照剪辑制片等电子软件工程技术人员，含本公司人员 及为制造者服务的人员，后者由制作者根据个人意愿填写）'
          }
        ]
      }
    },
	
	// 任务详情页通过 onLoad 生命周期中接传递过来的参数 id
	onLoad(option){
		alert(111)
	 // console.log('上一个页面传递过来的参数', option)
	 // console.log('id', option.id)
	 // console.log('item', option.item)
	 // alert(option.query.id)
	 // 接下来就是你需要该id做些什么，比如通过id查询到该详情等
	},
	
	create(){
			alert(333)
	},
	
	
    methods: {
      handleClose(tag) {
        this.tags.splice(this.tags.indexOf(tag), 1);
      },

      /**
       * 打开某个页面
       * @param row
       */
      openPage(row) {
        console.log(row);
        let routeData = this.$router.resolve({
          name : '教材书籍'
        })
        console.log(routeData.href);
        window.open(routeData.href, "_blank")
      }
    }
  }
</script>

<style scoped lang="scss">

  .margin-top {
    margin-top: 20px;
  }

  .study-list {
    .el-card {
      /deep/ .el-card__body {
        padding-bottom: 0;
      }

      &.padding-bottom {
        padding-bottom: 20px;
      }
    }

    /deep/ .el-form-item__label {
      font-weight: bold;
    }

    /deep/ .el-form-item__content {
      margin-left: 80px;
    }

    .el-select {
      margin-right: 10px;
      margin-bottom: 8px;
    }

    .el-tag {
      color: $green;
      background-color: #ffffff;
      border-color: $green;
      margin-right: 10px;

      /deep/ .el-tag__close {
        color: $green;

        &:hover {
          color: #ffffff;
          background-color: $green;
        }
      }
    }

    .el-image:hover {
      cursor: pointer;
    }

    .el-pagination {
      text-align: center;
      margin-top: 20px;
    }
  }

</style>
